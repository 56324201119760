import React from "react";

export default () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <g fillRule="evenodd" strokeWidth="1pt">
        <path fill="#fff" d="M0 0h512v512H0z"></path>
        <path fill="#009246" d="M0 0h170.7v512H0z"></path>
        <path fill="#ce2b37" d="M341.3 0H512v512H341.3z"></path>
      </g>
    </svg>
  );
};
