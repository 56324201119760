import React, { useEffect, useState, useContext } from "react";
import { StyledFastChooseItems, StyledButtonCTA } from "./style";
import { StyledGridRow } from "../../commons/Grid";
import useApi from "../../hooks/useApi";
import ApiErrorForm from "../ApiErrorForm/ApiErrorForm";
import { DataContext } from "../../contexts/DataContext";
import { LayoutContext } from "../../contexts/LayoutContext";
import Card from "./Card";
import { Select } from "@3beehivetech/components";
import { useForm } from "react-hook-form";
import MarketCountriesDropdown from "../MarketCountriesDropdown/MarketCountriesDropdown";
import { Button } from "@3beehivetech/components";
import Link from "../Link/Link";

import {
  StyledIntro,
  StyledIntroTitle,
  StyledIntroSubtitle,
  StyledIntroBigText,
  StyledContainerCards,
} from "../Intro_v2/style.jsx";

const MAX_HIVES = 3;

const CardsAlveari = ({
  title,
  subtitle,
  bigText,
  textAlign,
}) => {
  const {
    getHives_v2, getAddons, getCountries, getRegions, getPlants, 
  } = useApi();
  const { setModalContent } = useContext(LayoutContext);
  const [hives, setHives] = useState([]);
  const [addons, setAddons] = useState([]);
  const [countries, setCountries] = useState();
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState();
  const [honeys, setHoneys] = useState([]);
  const [selectedHoney, setSelectedHoney] = useState(null);
  const { languages, labels, i18nOasiLink } = useContext(DataContext);

  const form = useForm();
  const marketCountry = form.watch("marketCountry");

  const currentLanguage = languages.filter((language) => language.isCurrent);
  const currentLanguageCode = currentLanguage ? currentLanguage[0].label : "en";

  /*useEffect(() => {
    const _urlParams = new URLSearchParams(location.search)
    const AB = _urlParams.get('AB')
  }, [])*/

  useEffect(() => {
    setSelectedHoney(null);
    getPlants({
      coupon: null,
      country: marketCountry,
      region: selectedRegion,
      lang: currentLanguageCode,  
    }).then((results) => {setHoneys(results.filter((honey)=>honey.av_quantity > 0.25));});
  }, [selectedRegion, marketCountry, currentLanguageCode]);

  useEffect(() => {
    if (!marketCountry) {
      return;
    }
    getHives_v2({
      page: 1,
      lang: currentLanguageCode,
      country: marketCountry,
      region: selectedRegion,
      page_size: 10,
      honeyID: selectedHoney,
    }).then((results) => {
      const _hives = results.sort(() => 0.5 - Math.random());
      //console.log(Date.parse(results[0].honey.shipping_dates))
      //var _hives = results.sort((date1, date2) => Date.parse(date1.honey.shipping_dates) - Date.parse(date2.honey.shipping_dates));
      console.log(_hives)
      if (_hives.error) {
        setModalContent(<ApiErrorForm message={_hives.message} />);
      } else {
        const _hives_filtered = _hives.filter(
          (hive) => (hive.main_image !== null) && (hive.gallery.length > 0) && (hive.honey.available_plans.length > 0),
        );
        if (_hives_filtered.length > 2) {setHives(_hives_filtered.slice(0, MAX_HIVES));} else {setHives(_hives.slice(0, MAX_HIVES));}
      }
    });
  }, [
    currentLanguageCode,
    marketCountry,
    selectedRegion,
    setModalContent,
    selectedHoney,
    getHives_v2,
  ]);

  useEffect(() => {
    getAddons({
      country: currentLanguageCode,
    }).then((_addons) => {
      if (_addons.error) {
        setModalContent(<ApiErrorForm message={_addons.message} />);
      } else {
        setAddons(_addons);
      }
    });
  }, [currentLanguageCode, setModalContent, getAddons]);

  useEffect(() => {
    getCountries().then((_countries) => {
      if (_countries.error) {
        setModalContent(<ApiErrorForm message={_countries.message} />);
      } else {
        setCountries(_countries);
        form.setValue(
          "marketCountry",
          _countries.market_countries.find(
            (e) => e.toLowerCase() === currentLanguageCode,
          ) ?? "IT",
        );
      }
    });
  }, [setModalContent, getCountries, form, currentLanguageCode]);

  useEffect(() => {
    setSelectedRegion();
    setRegions([]);
    getRegions({ country: marketCountry }).then((_regions) => {
      if (_regions.error) {
        setModalContent(<ApiErrorForm message={_regions.message} />);
      } else {
        setRegions(_regions.av_regions.sort((a, b) => (a > b ? 1 : -1)));
      }
    });
  }, [marketCountry, setModalContent, getRegions]);

  return (
    /* <>
      {AB == 'true' && (*/
    <>
      <StyledGridRow noMargin id="cardsAlveari">
        <StyledIntro marginBottom="0px" textAlign={textAlign}>
          {title && (
            <StyledIntroTitle dangerouslySetInnerHTML={{ __html: title }} />
          )}

          {bigText && (
            <StyledIntroBigText dangerouslySetInnerHTML={{ __html: bigText }} />
          )}

          {subtitle && (
            <StyledIntroSubtitle
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
          )}
        </StyledIntro>
        <div className="flex" style="margin-top: 20px; margin-bottom:20px; gap: 16px; flex-flow:wrap">
          {marketCountry && (
            <MarketCountriesDropdown
              countries={countries.market_countries}
              form={form}
            />
          )}
          {regions.length > 0 && (
            <div style={{ width: "10rem" }}>
              <Select
                value={selectedRegion}
                onChange={(region) => setSelectedRegion(region)}
              >
                <Select.Button>
                  {selectedRegion ?? labels.shopCard.filters.region}
                </Select.Button>
                <Select.Options>
                  <Select.Option
                    value={null}
                    label={labels.shopCard.filters.region}
                  />
                  {regions.map((value) => (
                    <Select.Option value={value} label={value} key={value} />
                  ))}
                </Select.Options>
              </Select>
            </div>
          )}
          {honeys?.length > 0 && (
            <div style={{ width: "10rem" }}>
              <Select
                value={selectedHoney}
                onChange={(honey) => setSelectedHoney(honey)}
              >
                <Select.Button>
                  {selectedHoney ? honeys.find(honey => honey.id === selectedHoney).name : labels.HONEY }
                </Select.Button>
                <Select.Options>
                  <Select.Option
                    value={null}
                    label={labels.shopCard.honey}
                  />
                  {honeys.map((value) => (
                    <Select.Option value={value.id} label={value.name +" | "+value.meaning} key={value.id} />
                  ))}
                </Select.Options>
              </Select>
            </div>
          )}
        </div>
      </StyledGridRow>
      <StyledContainerCards>
        <StyledFastChooseItems>
          {hives.map((hive, hiveIndex) => (
            <Card
              key={hiveIndex}
              hive={hive}
              hiveIndex={hiveIndex}
              addons={addons}
              countries={countries}
            />
          ))}
        </StyledFastChooseItems>
      </StyledContainerCards>
      <StyledButtonCTA>
        <Link
          to={i18nOasiLink("/alveari")}>
          <Button variant="pink">
            {labels.allHives}
          </Button>
        </Link>
      </StyledButtonCTA>
    </>
    /*  )}
    </>*/
  );
};

export default CardsAlveari;
