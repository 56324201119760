import React from "react";
import { Controller } from "react-hook-form";
import { Select } from "@3beehivetech/components";
import useTranslation from "../../hooks/useTranslation";
import { flagIconsMap } from "../../utils/flagIconsMap";

const MarketCountriesDropdown = ({
  countries, form,
}) => {
  const { t } = useTranslation();

  return (
    <div className="w-32">
      <Controller
        control={form.control}
        name="marketCountry"
        render={({ field }) => (
          <Select {...field}>
            <Select.Button>
              <div className="flex items-center h-full space-x-2">
                <div className="flex items-center w-6 h-6 overflow-hidden rounded-lg">
                  {
                    flagIconsMap[
                      field.value.toLowerCase()
                    ]
                  }
                </div>
                <div style={{ width: 4 }}></div>
                <span>{t(`plansCardList.market.${field.value}`)}</span>
              </div>
            </Select.Button>
            <Select.Options>
              {countries.map(c => (
                <Select.Option
                  key={c}
                  value={c}
                  label={t(`plansCardList.market.${c}`)}
                />
              ))}
            </Select.Options>
          </Select>
        )}
      />
    </div>
  );
};
  
export default MarketCountriesDropdown;
