import React from "react";
import ItalyIcon from "../components/Icon/SVG/ItalyIcon";
import EnglandIcon from "../components/Icon/SVG/EnglandIcon";
import SpainIcon from "../components/Icon/SVG/SpainIcon";
import GermanyIcon from "../components/Icon/SVG/GermanyIcon";
import FrenchIcon from "../components/Icon/SVG/FrenchIcon";

export const flagIconsMap = {
  it: <ItalyIcon />,
  en: <EnglandIcon />,
  es: <SpainIcon />,
  de: <GermanyIcon />,
  fr: <FrenchIcon />,
};
